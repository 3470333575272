.parentContainer {
  flex: 1;
  direction: column;
  position: fixed;
  top: 0;
  width: 100%;
  display: block;
  background-size: cover;
  background-attachment: fixed;
  height: 100%;
}

.mainContainer {
  display: flex;
  flex: 1;
  position: relative;
  padding-top: 5;
}

 .childContainer {
   position: absolute;
   display: flex;
   flex: 1;
   width: 100%;
   height: 100%;
   background: rgba(0, 0, 0, 0.5);
   justify-content: center;
   align-items: center;
   flex-direction: column;
}

 .FooterPage {
  text-align: center;
  display: flex;
  overflow: auto;
  width: 100%;
  background-color: #035f6d;
  position: fixed;
  bottom: 0;
  height: 5%;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #e7e7e7;
}

.NavBarStyles {
  background-color: #003b44;
  width: 100%;
}

.ContentMargin {
  margin: 2%;
}

.homeMain {
  flex: 1;
  direction: column;
  position: relative;
  top: 0;
  width: 100%;
  display: block;
  height: 100%;
  overflow-x: hidden;
}

.h1,
 h2,
 h3,
 h4,
 h5,
 h6 {
  font-family: "Archivo Black";
}

.h1 {
  font-size: 60px !important;
  text-transform: uppercase;
  color: #fff;
  font-weight: bold !important;
}
.h2 {
  font-size: 50px !important;
  text-transform: uppercase;
  color: #fff;
  font-weight: bold !important;
}
.h3 {
  font-size: 40px !important;
  text-transform: uppercase;
  color: #fff;
  font-weight: bold !important;
}

.h6 {
  font-size: 2vw;
  font-weight: bold;
}

.ImgResp {
  display: block;
  max-width: 100%;
  width: 100%;
  height: auto;
}

.homeImgResp {
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  display: block;
  padding: 0;
}

.over-lay-intellize {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.intellize-banner-container {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -40%);
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

#borderbelow:after {
  font-size: 2vw;
  position: relative;
  display: block;
  height: 5px;
  width: 80px;
  background-color: red;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 200%);
}

h6:after {
  content: "";
  position: absolute;
  height: 5px;
  width: 70px;
  background-color: red;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0%);
}


.bgDark {
  background-color: #035f6d !important;
}

.EmptyDiv {
  height: 200px;
  width: 100%;
  clear: both;
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .underlineText {
    border-bottom: 0.1px solid rgb(250, 246, 246) !important;
  }
  .h1 {
    font-size: 30px !important;
    text-transform: uppercase;
    color: #fff;
    font-weight: bold !important;
  }

  .h2 {
    font-size: 20px !important;
    text-transform: uppercase;
    color: #fff;
    font-weight: bold !important;
  }

  .h3 {
    font-size: 16px !important;
    text-transform: uppercase;
    color: #fff;
    font-weight: bold !important;
  }
}

@media only screen and (max-width: 479px) {
  .underlineText {
    border-bottom: 0.1px solid rgb(250, 246, 246) !important;
  }
  .h1 {
    font-size: 30px !important;
    text-transform: uppercase;
    color: #fff;
    font-weight: bold !important;
  }

  .h2 {
    font-size: 20px !important;
    text-transform: uppercase;
    color: #fff;
    font-weight: bold !important;
  }

  .h3 {
    font-size: 16px !important;
    text-transform: uppercase;
    color: #fff;
    font-weight: bold !important;
  }
}

.layer {
  background-color: rgb(3, 95, 109, 0.5);
  top: 0;
  left: 0;
  width: 100%;
}

.serviceImage {
  flex: 1;
  max-width: 25%;
  background-color: "red";
}

.keypoints h5 {
  position: relative;
  color: #fff;
  padding-left: 15px;
}

.keypoints h5:after {
  content: "";
  height: 100%;
  width: 5px;
  background-color: red;
  position: absolute;
  left: 0;
  top: 0;
}

.keypoints i {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  text-align: center;
  line-height: 200px;
  background-color: #f5f5f5;
  color: #071d1d;
}

.keypoints h6:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0%);
  height: 5px;
  width: 50px;
  background-color: red;
}

.keypoints h6 {
  position: relative;
  padding-bottom: 15px;
}

.active {
  background-color: rgba(72, 122, 129, 0.5);
  color: white;
  border-bottom: 1mm solid red;
}
